import React, { useState, useEffect, useRef } from "react";
import { FormLabel, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import Checkbox from "components/checkbox";

const MultiSelect = ({
  formFieldName,
  options = [],
  onChange,
  initialVal = [],
  placeholder = "Select",
  label,
  id,
  extra,
  required,
  variant,
  state,
  disabled,
  managersEmails = [],
  minTagsToShow = 10,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(() => {
    const initialSelectedOptions = initialVal.map((opt) => String(opt));
    const managerOptions = options
      .filter((opt) => managersEmails.includes(opt.id))
      .map((opt) => String(opt.id));
    return Array.from(new Set([...initialSelectedOptions, ...managerOptions]));
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const optionsListRef = useRef(null);

  // Function to determine the initial select all state
  const calculateSelectAllState = (selectedOptions, options, managersEmails) => {
    const managerOptions = options
      .filter((opt) => managersEmails.includes(opt.id))
      .map((opt) => String(opt.id));
    return (
      selectedOptions.length ===
      options.filter((opt) => !managersEmails.includes(opt.id)).length +
        managerOptions.length
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsListRef.current && !optionsListRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Wrap the logic inside a conditional to avoid unnecessary updates
    const initialSelectedOptions = initialVal.map((opt) => String(opt));
    const managerOptions = options
      .filter((opt) => managersEmails.includes(opt.id))
      .map((opt) => String(opt.id));
    const uniqueOptions = Array.from(
      new Set([...initialSelectedOptions, ...managerOptions])
    );

    // Only set state if the values are different
    if (JSON.stringify(uniqueOptions) !== JSON.stringify(selectedOptions)) {
      setSelectedOptions(uniqueOptions);
      setIsSelectAll(
        calculateSelectAllState(uniqueOptions, options, managersEmails)
      );
    }
    // Add necessary dependencies to avoid re-triggering unnecessarily
  }, [initialVal, options, managersEmails]);

  const toggleDropdown = () => {
    if (!disabled) {
      setDropdownOpen(!dropdownOpen);
    }
  };

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const option = e.target.value;

    const selectedOptionSet = new Set(selectedOptions);

    if (isChecked) {
      selectedOptionSet.add(option);
    } else {
      selectedOptionSet.delete(option);
    }

    const newSelectedOptions = Array.from(selectedOptionSet);
    const managerOptions = options
      .filter((opt) => managersEmails.includes(opt.id))
      .map((opt) => String(opt.id));
    const finalSelectedOptions = Array.from(
      new Set([...newSelectedOptions, ...managerOptions])
    );

    setSelectedOptions(finalSelectedOptions);
    onChange(finalSelectedOptions);
    setIsSelectAll(
      calculateSelectAllState(finalSelectedOptions, options, managersEmails)
    );
  };

  const handleSelectAllClick = () => {
    if (isSelectAll) {
      const managerOptions = options
        .filter((opt) => managersEmails.includes(opt.id))
        .map((opt) => String(opt.id));
      setSelectedOptions(managerOptions);
      onChange(managerOptions);
      setIsSelectAll(false);
    } else {
      const allOptions = options
        .filter((opt) => !managersEmails.includes(opt.id))
        .map((opt) => String(opt.id));
      const managerOptions = options
        .filter((opt) => managersEmails.includes(opt.id))
        .map((opt) => String(opt.id));
      const finalOptions = Array.from(
        new Set([...allOptions, ...managerOptions])
      );
      setSelectedOptions(finalOptions);
      onChange(finalOptions);
      setIsSelectAll(true);
    }
  };

  const handleRemoveChip = (option) => {
    const newSelectedOptions = selectedOptions.filter((opt) => opt !== option);
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  return (
    <div className={`${extra}`}>
      <FormLabel
        htmlFor={id}
        className={`!text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "font-bold"
        }`}
      >
        {label}
      </FormLabel>
      <div className="relative w-full" ref={optionsListRef}>
        <div
          className={`mt-2 flex min-h-12 w-full items-center rounded-xl border bg-white/0 p-3 text-md outline-none cursor-pointer ${
            dropdownOpen ? "border-gray-400" : "border-gray-200 dark:!border-white/10 dark:text-white"
          } ${disabled ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]" : ""}`}
          onMouseDown={toggleDropdown}
        >
          {selectedOptions.length === 0 ? (
            <span className="max-w-[100px] truncate">{placeholder}</span>
          ) : (
            <div className="flex flex-wrap gap-1">
              {selectedOptions.length > minTagsToShow ? (
                <Tag size="sm" borderRadius="full" variant="solid" bg="rgb(242, 136, 32)" color="white" className="p-2">
                  <TagLabel>{`${selectedOptions.length} selected`}</TagLabel>
                </Tag>
              ) : (
                selectedOptions.map((optionId) => {
                  const option = options.find((opt) => String(opt.id) === optionId);
                  const isDisabled = managersEmails.includes(option.id);
                  return (
                    <Tag
                      size="sm"
                      key={optionId}
                      borderRadius="full"
                      variant="solid"
                      bg={`${isDisabled ? "#a0aec0" : "rgb(242, 136, 32)"}`}
                      color="white"
                      className="px-2 py-1"
                    >
                      <TagLabel>{option?.name}</TagLabel>
                      {!isDisabled && (
                        <TagCloseButton
                          onClick={() => handleRemoveChip(optionId)}
                        />
                      )}
                    </Tag>
                  );
                })
              )}
            </div>
          )}
          <span className="ml-auto text-xs transform transition-transform">▼</span>
        </div>

        <div className={`absolute shadow-md bg-white z-[1500] rounded border min-w-max transition-opacity ${dropdownOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"} w-full max-h-[calc(100vh-585px)] overflow-y-scroll mt-1`}>
          <ul className="w-full">
            <li key="select-all" className="pl-3 dark:text-white dark:bg-navy-800">
              <label className={`flex items-center whitespace-nowrap cursor-pointer w-full px-2 py-1 transition-colors hover:bg-lightPrimary [&:has(input:checked)]:bg-lightPrimary dark:hover:bg-brand-600 dark:[&:has(input:checked)]:bg-brand-600`}>
                <Checkbox
                  name="select-all"
                  checked={isSelectAll}
                  onChange={handleSelectAllClick}
                />
                <span className="ml-2">Select All</span>
              </label>
            </li>
            {options.map((option) => {
              const isDisabled = managersEmails.includes(option.id);
              return (
                <li key={String(option.id)} className="pl-3 dark:text-white dark:bg-navy-800">
                  <label className={`flex items-center whitespace-nowrap cursor-pointer w-full px-2 py-1 transition-colors hover:bg-lightPrimary [&:has(input:checked)]:bg-lightPrimary dark:hover:bg-brand-600 dark:[&:has(input:checked)]:bg-brand-600`}>
                    <Checkbox
                      name={formFieldName}
                      value={String(option.id)}
                      checked={selectedOptions.includes(String(option.id))}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                    <span className={`ml-2 ${isDisabled ? 'text-gray-400' : ''} `}>{option.name}</span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
